import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import * as R from 'ramda'

import { moveOrderRequest } from '../../../../modules/boards'
import { getIsOrganization } from '../../../../modules/orders'

import ModalPortal from '../ModalPortal'
import { BottomButtons } from './index'
import OpenModalButton from './card/OpenModalButton'

const Container = styled.div`
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  margin-bottom: 5px;
  user-select: none;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.7);
  margin-top: 2px;
`

const AmoId = styled.div`
  font-family: FuturaMediumC;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.9);
`

const Time = styled.div`
  font-family: FuturaBookC sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
`

const Price = styled.div`
  font-family: FuturaMediumC;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  margin-right: 5px;
`

const Product = styled.div`
  font-family: FuturaMediumC;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  border: 1px solid rgba(18, 22, 26, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 5px;
`

const Info = styled.div`
  display: flex;
`

const Organization = styled.div`
  font-family: FuturaMediumC;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.7);
  margin-top: 2px;
`

const Dot = styled.div`
  background: ${({ different }) => {
    if (different > 1) {
      return '#0BD781'
    } else if (different === 0) {
      return '#FAB400'
    } else if (different < 0) {
      return '#F55555'
    }
  }};
  border-radius: 50%;
  width: 5px;
  height: 5px;
`

const ListItem = styled.div`
  margin-bottom: 10px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 18px;
  line-height: 30px;
  color: rgba(18, 22, 26, 0.7);
`

const Address = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 9px;
  color: rgba(18, 22, 26, 0.7);
  margin-bottom: 2px;
`

const StatusContainer = styled.div`
  overflow: scroll;
  max-height: 300px;
`

const Card = ({
                order,
                columnsMeta,
                columnId,
                moveOrder,
                isOrganization,
                handleGetOrders,
              }) => {
  const {
    orderNumber,
    date,
    organization,
    price,
    product,
    transportId,
    id,
  } = order
  const [modalOpen, setIsOpen] = useState(false)

  const renderModal = () => {
    if (!modalOpen) return null
    const close = () => {
      setIsOpen(false)
    }

    const onClick = nextColumn => {
      if (nextColumn.id !== columnId) {
        moveOrder({
          leadStatusId: nextColumn.id,
          id,
          isOrganization,
          columnId,
        })
      }
      close()
    }

    return (
      <ModalPortal close={close} header='Cтатус заявки'>
        <StatusContainer>
        {columnsMeta
          .filter(el => el.id !== columnId)
          .map(el => (
            <ListItem onClick={() => onClick(el)} key={el.id}>
              {el.title}
            </ListItem>
          ))}
        </StatusContainer>
        <OpenModalButton
          order={order}
          closeParent={close}
          handleGetOrders={handleGetOrders}
        />
        <BottomButtons order={order} columnId={columnId} close={close} />
      </ModalPortal>
    )
  }

  const today = moment()
  const orderDate = moment(JSON.parse(date).date)
  const daysDiffFromOrderDate = Math.ceil(
    moment.duration(orderDate.diff(today)).asDays(),
  )
  const address =
    JSON.parse(order.address).address.length > 35
      ? `${JSON.parse(order.address).address.slice(0, 35)}...`
      : JSON.parse(order.address).address
  return (
    <>
      <Container onClick={() => setIsOpen(true)}>
        <Header>
          <AmoId>№{orderNumber}</AmoId>
          <Time>{JSON.parse(date)?.text}</Time>
        </Header>
        <Organization>{organization}</Organization>
        <Footer>
          <Info>
            <Price>{Number(price)} ₽</Price>
            <Product>
              {product.length > 35 ? `${product.slice(0, 35)}...` : product}
            </Product>
          </Info>
        </Footer>
        <Footer>
          <Info>
            <Address>{address}</Address>
          </Info>
          {transportId ? null : <Dot different={daysDiffFromOrderDate} />}
        </Footer>
        {transportId ? (
          <Footer>
            <Price>{order.driverName}</Price>
            <Price>{order.carNumber}</Price>
          </Footer>
        ) : null}
      </Container>
      {renderModal()}
    </>
  )
}

export default connect(
  R.applySpec({
    isOrganization: getIsOrganization,
  }),
  {
    moveOrder: moveOrderRequest,
  },
)(Card)
