import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Card } from './index'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { getTransport } from '../../../../modules/transport'
const ColumnWrapper = styled.div`
  box-sizing: border-box;
  width: calc(100% - 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: none;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`

const ColumnTitle = styled.div`
  font-family: FuturaDemiC;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(18, 22, 26, 0.9);
`

const ColumnTotal = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: rgba(18, 22, 26, 0.7);
  margin-bottom: 10px;
`

const totalPriceReducer = (accumulator, currentValue) =>
  accumulator + Number(currentValue.price)


const Column = ({ column, columnsMeta, handleGetOrders, transports }) => {
  const totalPrice = useMemo(
    () => {
      const { orders = [] } = column
      return orders.reduce(totalPriceReducer, 0)
    },
    [column],
  )
  const { orders = [] } = column
  orders.forEach(quote => {
    for (const transport of transports) {
      if (quote.transportId === transport.transportId) {
        quote.driverName = transport.fio;
        quote.carNumber = transport.carNumber;
        break;
      }
    }
  })
  const renderCards = () => orders
    .sort((a, b) => b.id - a.id)
    .map(order => (
      <Card
        handleGetOrders={handleGetOrders}
        order={order}
        columnsMeta={columnsMeta}
        key={order.id}
        columnId={column.id} />
    ))

  return (
    <ColumnWrapper>
      <ColumnTitle>{column.title}</ColumnTitle>
      <ColumnTotal>  {orders.length} сделки: {totalPrice} ₽</ColumnTotal>
      {renderCards()}
    </ColumnWrapper>
  )
}

export default connect(
  R.applySpec({
    transports: getTransport,
  }),
)(Column)