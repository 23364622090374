import React, { useCallback } from 'react'
import NumberFormat from "react-number-format"

export const PercentNumberInput = ({ inputRef, onChange, name, ...other }) => {
  const handleChange = useCallback(values => {
    onChange({
      target: { name, value: values.floatValue == null ? null : values.floatValue },
    })
  }, [onChange, name])
  return <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={handleChange}
    suffix="%"
    isAllowed={(values) => {
      const {floatValue} = values;
      return (floatValue >= 0 && floatValue <= 100) || !floatValue;
    }}
  />;
}
