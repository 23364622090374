import * as R from 'ramda'

export const getStorages = R.prop('storages')

export const getIsLoading = R.pipe(getStorages, R.prop('isLoading'))

const getById = R.pipe(getStorages, R.prop('byId'))

const getAllIds = R.pipe(getStorages, R.prop('allIds'))

export const getActiveSort = R.pipe(getStorages, R.prop('activeSort'))

export const getStorageList = R.converge(
  (byId, allIds) => R?.map(x => byId[x])(allIds),
  [getById, getAllIds],
)

export const getStoragesList = R.pipe(
  getStorages,
  R.prop('getStorages'),
)

export const getSearchedStorage = R.pipe(
  getStorages,
  R.prop('searchedStorage'),
)

export const getSelectedStorage = R.pipe(
  getStorages,
  R.prop('selectedStorage'),
)

export const getIsPostingStorage = R.pipe(
  getStorages,
  R.prop('isPostingStorage'),
)

export const getIsCreatingStorage = R.pipe(
  getStorages,
  R.prop('isCreatingStorage'),
)