import { call, all, put, takeLatest, select } from 'redux-saga/effects'
import {
  getActiveSortSuccess,
  getStoragesSuccess,
  getStoragesFailure,
  getStoragesRequest,
  getAvailableStoragesSuccess,
  getAvailableStoragesFailure,
  getAvailableStoragesRequest,
  deleteStorageSuccess,
  updateStorageFailure,
  deleteStorageRequest,
  updateStorageRequest,
  createStorageSuccess,
  createStorageFailure,
  updateStorageSuccess,
  createStorageRequest,
} from './duck'
import * as StoragesManager from './StoragesManager'
import { getToken } from '../auth'
import { getSearchedStorage, getSelectedStorage } from './selectors'
import { setTaskModalVisible } from '../ui'

function* getStoragesSaga({ payload }) {
  try {
    const accessToken = yield select(getToken)
    const searchedStorage = yield select(getSearchedStorage)
    const sortKey = payload?.sortKey ? payload.sortKey : 'title'
    const sortDirection = payload?.sortDirection ? payload.sortDirection : 'asc'
    const storages = yield call(StoragesManager.getStorages, {
      accessToken,
      search: {
        search: searchedStorage !== '' ? searchedStorage : '',
        sortKey,
        sortDirection,
      },
    })
    yield put(getActiveSortSuccess({ sortKey, sortDirection }))
    yield put(getStoragesSuccess(storages))
  } catch (ex) {
    yield put(getStoragesFailure(ex.localeMessage))
  }
}

function* getAvailableStoragesSaga({}) {
  try {
    const token = yield select(getToken)
    const data = {
      token,
    }

    const storages = yield call(StoragesManager.getAmoStorages, data)
    yield put(getAvailableStoragesSuccess(storages))
  } catch (ex) {
    yield put(getAvailableStoragesFailure())
  }
}

function* removeStorageSaga({ payload: { storageId } }) {
  try {
    const accessToken = yield select(getToken)
    yield call(
      StoragesManager.deleteStorageRequest,
      {
        accessToken,
      },
      storageId,
    )
    yield put(deleteStorageSuccess(storageId))
    yield put(getStoragesRequest())
  } catch (ex) {
    yield put(updateStorageFailure())
  }
}

function* updateStorageSaga(
  {
    payload: {
      id,
      address,
      isActive,
      name,
      phone,
      price,
    },
  }) {
  try {
    const accessToken = yield select(getToken)
    const phoneWithCode = '+7' + phone.replace(/[^+\d]/g, '')
    const body = {
      id,
      address,
      isActive,
      name,
      phone: phoneWithCode,
      price,
    }

    yield call(
      StoragesManager.updateStorageRequest,
      {
        body,
        accessToken,
      },
    )

    yield put(updateStorageSuccess())
    yield put(getStoragesRequest())
  } catch (ex) {
    yield put(updateStorageFailure(ex))
  }

  yield put(setTaskModalVisible(false))
}

function* createStorageSaga(
  {
    payload: {
      address,
      isActive,
      name,
      phone,
      price,
    },
  }) {
  const phoneWithCode = '+7' + phone.replace(/[^+\d]/g, '')
  try {
    const accessToken = yield select(getToken)
    const body = {
      address,
      isActive,
      name,
      phone: phoneWithCode,
      price,
    }

    yield call(StoragesManager.createStorage, {
      body,
      accessToken,
    })

    yield put(createStorageSuccess({ name }))
    yield put(getStoragesRequest())
  } catch (ex) {
    yield put(createStorageFailure(ex))
  }

  yield put(setTaskModalVisible(false))
}


function* storagesSaga() {
  yield all([
    takeLatest(
      [getStoragesRequest],
      getStoragesSaga,
    ),
    takeLatest(getAvailableStoragesRequest, getAvailableStoragesSaga),
    takeLatest(deleteStorageRequest, removeStorageSaga),
    takeLatest(updateStorageRequest, updateStorageSaga),
    takeLatest(createStorageRequest, createStorageSaga),
  ])
}

export default storagesSaga