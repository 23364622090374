import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  Button,
  Modal,
  TextField,
} from '@material-ui/core'

import StorageTextField from './storage-text-field'

import {
  createStorageRequest,
  deleteStorageRequest,
  getSelectedStorage,
  selectStorage,
  updateStorageRequest,
} from '../../../modules/storages'

import { useStyles } from './styles'
import assets from '../../../assets'
import NumberFormat from 'react-number-format'

const Wrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    overflow: auto;
    margin-bottom: 25px;
    box-sizing: border-box;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
`

const CreateTitle = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 300px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
`

const Title = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 262px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px 0 0 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    overflow: auto;
    height: 100%;
  }
`

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })`
  position: absolute;
  right: 50px;
  top: 8px;
  cursor: pointer;
`

const EditIcon = styled.img.attrs({ src: assets.img.pencilRed })``
const EditActiveIcon = styled.img.attrs({ src: assets.img.pencil })``
const EditIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 2px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
  linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  ${({ isActive }) =>
          isActive &&
          css`
            background: linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
          `}
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 5px 20px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  box-sizing: border-box;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: -2px;
`

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextContainer = styled.div`
  margin-top: 70px;
  font-family: FuturaBookC, sans-serif;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
  font-weight: normal;
`

const ButtonsContainer = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  background: #fff;
  justify-content: center;
`

const ConfirmButton = styled.button`
  width: 95px;
  height: 36px;
  background: linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-family: FuturaMediumC, sans-serif;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(0deg, #9ba1a6, #9ba1a6),
  linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  margin-left: 8px;
  cursor: pointer;
`

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format='+# (###) ###-##-##'
      allowEmptyFormatting
      mask='_'
    />
  )
}

const StorageCard = ({
                       selectedStorage,
                       setCardMode,
                       cardMode,
                       updateStorage,
                       createStorage,
                       deleteStorage,
                       selectStorage,
                       handleCloseUserCard,
                       setModalShow,
                       editAccess,
                     }) => {
  const classes = useStyles()
  const isEditMode = cardMode.open && cardMode.mode === 'EDIT'
  const isCreatingMode = cardMode.open && cardMode.mode === 'CREATE'

  const [isDeletingStorage, setIsDeletingStorage] = useState(false)
  const [isAddingDocument, setIsAddingDocument] = useState(false)

  const [storageEditData, setStorageEditData] = useState({
    id: '',
    address: '',
    isActive: false,
    name: '',
    phone: '',
    price: '',
  })
  const [storageCreateData, setStorageCreateData] = useState({
    address: '',
    isActive: false,
    name: '',
    phone: '',
    price: '',
  })

  useEffect(() => {
    if (isCreatingMode) {
      setStorageCreateData({
        address: '',
        isActive: false,
        name: '',
        phone: '',
        price: '',
      })
    }
  }, [isCreatingMode, isEditMode])

  const handleSubmit = e => {
    e.preventDefault()
    setCardMode({
      open: false,
      mode: '',
    })
    if (!isCreatingMode) {
      updateStorage(storageEditData)
      setStorageEditData({
        id: '',
        address: '',
        isActive: false,
        name: '',
        phone: '',
        price: '',
      })
    } else {
      if (storageCreateData.name) {
        createStorage(storageCreateData)
        setStorageCreateData({
          address: '',
          isActive: false,
          name: '',
          phone: '',
          price: '',
        })
      }
    }
    selectStorage({})
    handleCloseUserCard()
  }

  useEffect(() => {
    if (!isCreatingMode) {
      setStorageEditData({
        id: selectedStorage.id || '',
        address: selectedStorage.address || '',
        isActive: selectedStorage.isActive || false,
        name: selectedStorage.name || '',
        phone: selectedStorage.phone || '',
        price: selectedStorage.price || '',
      })
    }
  }, [selectedStorage])

  const handleEditIconClick = () => {
    if (isEditMode) {
      setCardMode({
        open: true,
        mode: 'VIEW',
      })
      setStorageEditData({
        id: selectedStorage.id || '',
        address: selectedStorage.address || '',
        isActive: selectedStorage.isActive || false,
        name: selectedStorage.name || '',
        phone: selectedStorage.phone || '',
        price: selectedStorage.price || '',
      })
    } else if (isCreatingMode) {
      setCardMode({
        open: false,
        mode: '',
      })
      setStorageCreateData({
        address: '',
        isActive: false,
        name: '',
        phone: '',
        price: '',
      })
    } else {
      setCardMode({
        open: true,
        mode: 'EDIT',
      })
    }
  }

  const handleDelete = () => {
    deleteStorage({ storageId: selectedStorage.id })
    setIsDeletingStorage(false)
    setCardMode({
      open: false,
      mode: '',
    })
    setStorageCreateData({
      address: '',
      isActive: false,
      name: '',
      phone: '',
      price: '',
    })
  }

  return (
    <Wrapper>
      <Modal
        open={isDeletingStorage}
        onClose={() => setIsDeletingStorage(false)}
      >
        <Container>
          <TextContainer>
            Удалить склад <br />
            {selectedStorage.name} ?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton
              onClick={() =>
                editAccess ? handleDelete() : setModalShow(true)
              }
            >
              Да
            </ConfirmButton>
            <CancelButton onClick={() => setIsDeletingStorage(false)}>
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </Container>
      </Modal>
      <TitleWrapper>
        {!isCreatingMode ? (
          <>
            <DeleteIcon onClick={() => setIsDeletingStorage(true)} />
            <Title>{isCreatingMode
              ? storageCreateData.name
              : storageEditData.name
              || 'Данные Склада'}</Title>
            <EditIconWrapper
              isActive={isEditMode}
              onClick={() =>
                editAccess ? handleEditIconClick() : setModalShow(true)
              }
            >
              {isEditMode ? <EditActiveIcon /> : <EditIcon />}
            </EditIconWrapper>
          </>
        ) : (
          <CreateTitle>Данные Нового склада</CreateTitle>
        )}
      </TitleWrapper>
      <FormWrapper>
        <StorageTextField
          label='Название'
          onChange={e =>
            isCreatingMode
              ? setStorageCreateData({
                ...storageCreateData,
                name: e.target.value,
              })
              : setStorageEditData({
                ...storageEditData,
                name: e.target.value,
              })
          }
          value={
            isCreatingMode
              ? storageCreateData.name
              : storageEditData.name
          }
          disabled={!isEditMode && !isCreatingMode}
        />
        <StorageTextField
          label='Адрес'
          onChange={e =>
            isCreatingMode
              ? setStorageCreateData({
                ...storageCreateData,
                address: e.target.value,
              })
              : setStorageEditData({
                ...storageEditData,
                address: e.target.value,
              })
          }
          value={
            isCreatingMode
              ? storageCreateData.address
              : storageEditData.address
          }
          disabled={!isEditMode && !isCreatingMode}
        />
        <TextField
          disabled={cardMode.mode === 'VIEW' && !isEditMode}
          value={isCreatingMode
            ? storageCreateData.phone
            : storageEditData.phone}
          onChange={e =>
            isCreatingMode
              ? setStorageCreateData({
                ...storageCreateData,
                phone: e.target.value,
              })
              : setStorageEditData({
                ...storageEditData,
                phone: e.target.value,
              })
          }
          InputLabelProps={{
            shrink: true,
          }}
          classes={{
            root: classes.input,
          }}
          variant='outlined'
          label='Телефон'
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        {(isEditMode || isCreatingMode) && !isAddingDocument && (
          <ButtonWrapper>
            <Button
              onClick={handleSubmit}
              classes={{
                root: classes.addButton,
              }}
            >
              {isCreatingMode ? 'Добавить' : 'Сохранить'}
            </Button>
            <Button
              onClick={handleEditIconClick}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Отмена
            </Button>
          </ButtonWrapper>
        )}
      </FormWrapper>
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    selectedStorage: getSelectedStorage,
  }),
  {
    updateStorage: updateStorageRequest,
    createStorage: createStorageRequest,
    deleteStorage: deleteStorageRequest,
    selectStorage,
  },
)(StorageCard)