import React, { useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
`

const ValueContainer = styled.div`
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: ${props => props.color ? `${props.color}` : 'black'};
`

const Container = styled.div`
  margin-bottom: 20px;
  width: 140px;
  box-sizing: border-box;
`

const ForecastWrapper = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(18, 22, 26, 0.9);
`

const ForecastContainer = styled.div`
  margin-top: 5px;
  display: flex;
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(18, 22, 26, 0.4);
`

const TitleText = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 5px;
`

const Statistic = ({ orders }) => {
  const allOrders = useCallback(
    () =>
      orders.reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          ...currentValue.orders,
        ],
        [],
      ),
    [orders],
  )

  const daysDiffFromOrderDate = allOrders().map(item => {
    const today = moment()
    const orderDate = moment(JSON.parse(item.date).date)
    return Math.ceil(moment.duration(orderDate.diff(today)).asDays())
  })

  const daysDiffFromToday = allOrders().map(item => {
    const today = moment()
    const orderDate = moment(item.createdAt)
    return Math.ceil(moment.duration(orderDate.diff(today)).asDays())
  })

  const totalPrice = allOrders().reduce(
    (previousValue, currentValue) => previousValue + Number(currentValue.price),
    0,
  )

  console.log(orders)
  const noManager = allOrders().filter(item => !item.managerId).length
  const today = daysDiffFromOrderDate.filter(item => item === -0).length
  const overdue = daysDiffFromOrderDate.filter(item => item < 0).length
  const newToday = daysDiffFromToday.filter(item => item === -0).length
  const newYesterday = daysDiffFromToday.filter(item => item === -1).length

  return (
    <Wrapper>
      <Container>
        <TitleText>Неназначенные:</TitleText>
        <ValueContainer color='#0BD781'>{noManager}</ValueContainer>
      </Container>
      <Container>
        <TitleText>Просроченные:</TitleText>
        <ValueContainer color='#F55555'>{overdue}</ValueContainer>
      </Container>
      <Container>
        <TitleText>На сегодня:</TitleText>
        <ValueContainer color='#FAB400'>{today}</ValueContainer>
      </Container>
      <Container>
        <TitleText>Новых на сегодня/вчера:</TitleText>
        <ValueContainer color='#999999'>{newToday}/{newYesterday}</ValueContainer>
      </Container>
      <ForecastWrapper>
        <div>Прогноз продаж:</div>
        <ForecastContainer>
          {allOrders().length} сделок: <span style={{ color: '#53585B', paddingLeft:"5px"}}> {totalPrice} ₽</span>
        </ForecastContainer>
      </ForecastWrapper>
    </Wrapper>
  )
}

export default Statistic