import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { Grid } from '@material-ui/core'

import { EntityList, Loader } from '../../components/shared'
import { useMediaQuery } from '../../components/address/commons'
import { widthMobileScreen } from '../../components/address/utils'
import AccessForbiddenPopup from '../../components/common/AccessForbidden'
import assets from '../../assets'
import {
  getAvailableStoragesRequest,
  getIsLoading,
  getSelectedStorage, getStorageList, getStoragesList, getStoragesRequest, selectStorage,
} from '../../modules/storages'
import { useWebSockets } from '../../context'
import StorageCard from '../../components/storages/storage-card'

const Wrap = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ecf1f4;
  box-sizing: border-box;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow: auto;
    height: 100%;
  }
`

const MobileWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`

const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``

const Wrapper = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-family: FuturaMediumC;
  font-size: 24px;
  text-transform: uppercase;
  color: #000000;
  opacity: 1;
  margin-right: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const RolesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Role = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  ${({ isActive }) =>
          isActive
                  ? css`
                    border: 1px solid #f49355;
                  `
                  : css`
                    border: 1px solid #ffffff;
                    background: #ffffff;
                    box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
                  `}
  border-radius: 8px;
  padding: 0 10px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  opacity: 0.6;
  cursor: pointer;
  margin: 0 5px 5px 0;
`

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #283642;
  margin-bottom: 25px;
`

const ArrowLeft = styled.img.attrs({ src: assets.img.arrowLeft })`
  width: 20px;
  margin-right: 10px;
`
const Storages = ({
                    isLoading,
                    storagesList,
                    selectStorage,
                    selectedStorage,
                    getStorages,
  getAllStorages,
  storages,
                  }) => {
  const [modalShow, setModalShow] = useState(false)

  const [editAccess, setEditAccesses] = useState([])

  const [viewAccess, setViewAccess] = useState([])

  const [modalViewShow, setModalViewShow] = useState(false)

  const { updatedData } = useWebSockets()

  const isMobile = useMediaQuery(`(max-width: ${widthMobileScreen - 1}px)`)

  const storageEvents = [
    'createStorage',
    'deleteStorage',
    'updateStorage',
  ]

  useEffect(() => {
    if (updatedData) {
      if (storageEvents.includes(updatedData.action)) {
        getStorages()
      }
    }
  }, [updatedData])

  useEffect(() => {
    getStorages()
    // getAllStorages()
  }, [])

  const [cardMode, setCardMode] = useState({
    open: false,
    mode: '',
  })
  const [userCardMode, setUserCardMode] = useState({
    open: false,
    mode: '',
  })

  const renderHeaderButton = () => (
    <HeaderTitle onClick={onClickBack}>
      {' '}
      {cardMode.open || userCardMode.open ? <ArrowLeft /> : null}Организации
    </HeaderTitle>
  )

  const onClickBack = () => {
    if (userCardMode.open) {
      // selectUser({})
      return handleCloseUserCard()
    }
    if (cardMode.open) {
      // selectOrganization({})
      return handleCloseCard()
    }
  }

  const handleCloseUserCard = () => {
    setUserCardMode({
      open: false,
      mode: '',
    })
  }

  const handleCloseCard = () => {
    setCardMode({
      open: false,
      mode: '',
    })
  }

  useEffect(() => {
    handleCloseUserCard()
  }, [selectedStorage])

  const renderAsDesktop = () => (
    <>
      <Wrapper>
        <TitleWrapper>
          <Title>Склады</Title>
        </TitleWrapper>
      </Wrapper>
      <Grid container wrap='nowrap'>
        <Grid item>
          <EntityList
            searchPlaceholder='Склады'
            entityList={storagesList}
            selectEntity={selectStorage}
            selectedEntity={selectedStorage}
            handleCloseCard={handleCloseCard}
            setCardMode={setCardMode}
            cardMode={cardMode}
            entityItemTitle='name'
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
        </Grid>
        {cardMode.open && (
          <Grid item>
            <StorageCard
              selectOrganization={selectStorage}
              handleCloseUserCard={handleCloseUserCard}
              cardMode={cardMode}
              setCardMode={setCardMode}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          </Grid>
        )}
      </Grid>
    </>
  )

  const renderAsMobile = () => (
    <div>sad</div>
  )
  const renderContent = () =>
    isMobile ? (
      <>
        {renderHeaderButton()}
        {renderAsMobile()}
      </>
    ) : (
      renderAsDesktop()
    )

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Склады'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrap>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Склады'}
          setModalShow={setModalShow}
        />
      )}
      {isLoading ? <StyledLoader /> : renderContent()}
    </Wrap>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    storagesList: getStoragesList,
    selectedStorage: getSelectedStorage,
    storages: getStorageList,
  }),
  {
    getStorages: getStoragesRequest,
    getAllStorages: getAvailableStoragesRequest,
    selectStorage,
  }
)(Storages)