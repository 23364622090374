import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'
import querystring from 'query-string'

export const getStorages = ({ accessToken, search }) =>
  fetch(
    ENDPOINTS.STORAGES(
      querystring.stringify(search, { arrayFormat: 'bracket' }),
    ),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then(handleStatuses())
    .then(res => res.json())

export const getAmoStorages = ({ token }) =>
  fetch(ENDPOINTS.GET_ALL_STORAGES(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const deleteStorageRequest = ({ accessToken }, id) =>
  fetch(ENDPOINTS.STORAGES(), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ id }),
  }).then(handleStatuses())

export const updateStorageRequest = ({ body, accessToken }) =>
  fetch(ENDPOINTS.STORAGES(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const createStorage = ({ body, accessToken }) => {
  return fetch(ENDPOINTS.STORAGES(), {
    method: 'POST',
    headers: {
      enctype: 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())
}