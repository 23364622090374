import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { ROUTES } from '../constants'
import { AuthRoute } from '../components/router-extensions'
import {
  Orders,
  Schedule,
  Categories,
  Promotions,
  Users,
  Moderation,
  Drivers,
  Organizations,
  Roles,
  Analytics,
  Storages,
} from '../screens'
import Transport from '../screens/Transport'
import Sidebar from '../components/common/sidebar'

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-x: scroll;
  @media (min-width: 1440px) {
    overflow-x: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-x: scroll;
  height: 100%;
  width: 100%;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  transition: margin-left .5s;
`

const Main = ({ isMobile, isMenuOpen,setMenuOpen }) => {
  const [mainState, setMainState] = useState({
    sidebarWidth: 58,
    contentMarginLeft: 58,
  })

  const handleHoverSidebar = useCallback(e => {
    if (e.nativeEvent.type === 'mouseover' && mainState.sidebarWidth === 58) {
      setMainState({
        sidebarWidth: 317,
        contentMarginLeft: 317,
      })
    } else {
      setMainState({
        sidebarWidth: 58,
        contentMarginLeft: 58,
      })
    }
  }, [])
  return (
    <Container>
      <Sidebar
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
        isMobile={isMobile}
        handleHoverSidebar={handleHoverSidebar}
        width={mainState.sidebarWidth}
      />
      <ContentContainer marginLeft={isMobile ? 0 : mainState.contentMarginLeft}>
        <AuthRoute exact path={ROUTES.ORDERS} component={Orders} />
        <AuthRoute path={ROUTES.CATEGORIES} component={Categories} />
        <AuthRoute path={ROUTES.PROMOTIONS} component={Promotions} />
        <AuthRoute path={ROUTES.MODERATION} component={Moderation} />
        <AuthRoute path={ROUTES.USERS} component={Users} />
        <AuthRoute path={ROUTES.DRIVERS} component={Drivers} />
        <AuthRoute path={ROUTES.SCHEDULE} component={Schedule} />
        <AuthRoute path={ROUTES.ORGANIZATIONS} component={Organizations} />
        <AuthRoute path={ROUTES.TRANSPORT} component={Transport} />
        <AuthRoute path={ROUTES.ROLES} component={Roles} />
        <AuthRoute path={ROUTES.STORAGES} component={Storages} />
        <AuthRoute path={ROUTES.ANALYTICS} component={Analytics} />
      </ContentContainer>
    </Container>
  )
}

export default Main
