import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import * as R from 'ramda'

import assets from '../../../assets'

const Wrapper = styled.div`
  min-width: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  background: transparent;
  overflow-y: scroll;
  max-height: 100vh;
  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    overflow: scroll;
    max-height: 100%;
    min-width: 250px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
`

const StyledInput = styled.input`
  box-sizing: border-box;
  padding: 10px 36px 8px 12px;
  border: 0;
  width: 100%;
  height: 36px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px 0 0 8px;
  outline: 0;
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

  ::placeholder {
    font-family: FuturaMediumC, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  }
`

const SearchIcon = styled.img.attrs({ src: assets.img.search })`
  position: absolute;
  right: 50px;
  top: 10px;
`

const AddIcon = styled.img.attrs({ src: assets.img.add })``
const AddWhiteIcon = styled.img.attrs({ src: assets.img.addWhite })``
const AddIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 2px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
  linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  ${({ isActive }) =>
          isActive &&
          css`
            background: linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
          `}
`

const EntityRow = styled.div`
  width: 100%;
  min-height: 47px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  font-family: FuturaBookC, sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
  padding: 12px 20px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  ${({ isSelected }) =>
          isSelected &&
          css`
            background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
            opacity: 0.8;
            color: #ffffff;
          `}
`

const EntityList = ({
                      entityList,
                      entityItemTitle,
                      selectedEntity,
                      selectEntity,
                      setCardMode,
                      cardMode,
                      handleCloseCard,
                      searchPlaceholder,
                      setModalShow,
                      editAccess,
                    }) => {
  const [search, setSearch] = useState('')

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }
  const handleRowClick = item => {
    if (R.equals(item, selectedEntity)) {
      selectEntity({})
      handleCloseCard()
    } else {
      selectEntity(item)
      setCardMode({
        open: true,
        mode: 'VIEW',
      })
    }
  }

  const isCreatingMode = cardMode.open && cardMode.mode === 'CREATE'

  const handleAddIconClick = () => {
    if (isCreatingMode) {
      handleCloseCard()
    } else {
      selectEntity({})
      setCardMode({
        open: true,
        mode: 'CREATE',
      })
    }
  }

  return (
    <Wrapper>
      <InputWrapper>
        <SearchIcon />
        <StyledInput
          value={search}
          onChange={handleSearchChange}
          placeholder={searchPlaceholder ? searchPlaceholder : 'Поиск'}
        />
        <AddIconWrapper
          isActive={isCreatingMode}
          onClick={() =>
            editAccess ? handleAddIconClick() : setModalShow(true)
          }
        >
          {isCreatingMode ? <AddWhiteIcon /> : <AddIcon />}
        </AddIconWrapper>
      </InputWrapper>
      {entityList
        .filter(value =>
          search
            ? value[entityItemTitle].toLowerCase().indexOf(search.toLowerCase()) !== -1
            : true,
        )
        .map(item => (
          <EntityRow
            isSelected={selectedEntity && item.id === selectedEntity.id}
            onClick={() => handleRowClick(item)}
            key={item.id}
          >
            {item[entityItemTitle]}
          </EntityRow>
        ))}
    </Wrapper>
  )
}

export default EntityList
