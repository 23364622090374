import * as R from 'ramda'
import { createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const getStoragesSuccess = createAction(
  `${MODULES.STORAGES}/GET_STORAGES_SUCCESS`,
)

export const getStoragesFailure = createAction(
  `${MODULES.STORAGES}/GET_STORAGES_FAILURE`,
)

export const getActiveSortSuccess = createAction(
  `${MODULES.STORAGES}/GET_ACTIVE_SORT_KEY_SUCCESS`,
)

export const getStoragesRequest = createAction(
  `${MODULES.STORAGES}/GET_STORAGES_REQUEST`,
)

export const selectStorage = createAction(
  `${MODULES.STORAGES}/SELECT_STORAGE`,
)

export const getAvailableStoragesRequest = createAction(
  `${MODULES.STORAGES}/GET_AVAILABLE_STORAGES_REQUEST`,
)

export const getAvailableStoragesSuccess = createAction(
  `${MODULES.STORAGES}/GET_AVAILABLE_STORAGES_SUCCESS`,
)
export const getAvailableStoragesFailure = createAction(
  `${MODULES.STORAGES}/GET_AVAILABLE_STORAGES_FAILURE`,
)

export const deleteStorageSuccess = createAction(
  `${MODULES.STORAGES}/DELETE_STORAGE_SUCCESS`,
)

export const updateStorageRequest = createAction(
  `${MODULES.STORAGES}/UPDATE_STORAGE_REQUEST`,
)

export const createStorageRequest = createAction(
  `${MODULES.STORAGES}/CREATE_STORAGE_REQUEST`,
)

export const deleteStorageRequest = createAction(
  `${MODULES.STORAGES}/DELETE_STORAGE_REQUEST`,
)

export const createStorageFailure = createAction(
  `${MODULES.STORAGES}/CREATE_STORAGE_FAILURE`,
)

export const createStorageSuccess = createAction(
  `${MODULES.STORAGES}/CREATE_STORAGE_SUCCESS`,
)

export const startCreatingStorage = createAction(
  `${MODULES.STORAGES}/START_CREATING_STORAGE`,
)

export const updateStorageSuccess = createAction(
  `${MODULES.STORAGES}/UPDATE_STORAGE_SUCCESS`,
)

export const stopCreatingStorage = createAction(
  `${MODULES.STORAGES}/STOP_CREATING_STORAGE`,
)

export const updateStorageFailure = createAction(
  `${MODULES.STORAGES}/UPDATE_STORAGE_FAILURE`,
)

const storageError = handleActions(
  {
    [createStorageFailure]: R.T,
    [createStorageSuccess]: R.F,
    [createStorageRequest]: R.F,
  },
  false,
)

const selectedStorage = handleActions(
  {
    [selectStorage]: (_, { payload }) => payload,
    [startCreatingStorage]: R.always({}),
    [updateStorageSuccess]: R.always({}),
  },
  {},
)

const isCreatingStorage = handleActions(
  {
    [startCreatingStorage]: R.T,
    [createStorageSuccess]: R.F,
    [createStorageFailure]: R.F,
    [selectStorage]: R.F,
    [stopCreatingStorage]: R.F,
  },
  null,
)

const isPostingStorage = handleActions(
  {
    [createStorageRequest]: R.T,
    [createStorageSuccess]: R.F,
    [createStorageFailure]: R.F,
  },
  null,
)

const byId = handleActions(
  {
    [getStoragesSuccess]: (state, { payload }) => ({
      ...state,
      ...R.indexBy(R.prop('id'))(payload),
    }),
    [deleteStorageSuccess]: (state, { payload: id }) =>
      R.dissoc(id, state),
  },
  {},
)

const activeSort = handleActions(
  {
    [getActiveSortSuccess]: (_, { payload }) => payload,
  },
  {},
)

const allIds = handleActions(
  {
    [getStoragesSuccess]: (state, { payload }) =>
      R.uniq([...state, ...R.map(R.prop('id'))(payload)]),
    [deleteStorageSuccess]: (state, { payload: id }) =>
      state.filter(x => x !== id),
  },
  [],
)

const getStorages = handleActions(
  {
    [getStoragesSuccess]: (_, { payload }) => payload,
  },
  [],
)

const isLoading = handleActions({
    [getStoragesRequest]: R.T,
    [getAvailableStoragesRequest]: R.T,
    [getStoragesSuccess]: R.F,
    [getStoragesFailure]: R.F,
    [createStorageSuccess]: R.F,
  }, null,
)

const storagesReducer = combineReducers({
  getStorages,
  byId,
  activeSort,
  allIds,
  isLoading,
  storageError,
  selectedStorage,
  isCreatingStorage,
  isPostingStorage,
})

export default storagesReducer